import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import { hyphenated } from "hyphenated"
import de from "hyphenated-de"
import moment from "moment"

import Seo from "../components/seo"
import Localize from "../components/localize"
import { NoData } from "../svgs/svg"

const Webinars = ({ data: { webinar, site }, pageContext, location }) => {
  const allWebinars = webinar.edges
  const lan = pageContext.locale
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      if (location.state && location.state.scroll) {
        window.scrollTo({
          top: location.state.scroll,
          left: 0,
          behavior: "smooth",
        })
      }
      window.addEventListener("scroll", handleScroll, { passive: true })
      return () => {
        window.removeEventListener("scroll", handleScroll, { passive: true })
      }
    })
  }, [location.state])

  const handleScroll = () => {
    var doc = document.documentElement
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    setScrollPosition(top)
  }

  return (
    <>
      <Seo
        lan={lan}
        title={lan === "en" ? "Webinars" : "Webinare"}
        description={
          lan === "en"
            ? "You can join our webinars from here."
            : "Von hier aus können Sie an unseren Webinaren teilnehmen."
        }
        url={`${site.siteMetadata.siteUrl}/${lan}/${pageContext.url}`}
        titleTemplate={
          lan === "en"
            ? "Webinars:HaackSchubert - Lawyers, tax consultants, auditors and notaries"
            : "Webinare: HaackSchubert - Rechtsanwälte, Steuerberater, Wirtschaftsprüfer und Notare"
        }
      />
      <div className="webinar-div no--margin-menu">
        <div id="news" className="news-box no--margin-menu padding--menu-sw">
          <div className="w-100">
            <ul className="ne-wrapper">
              {allWebinars.length > 0 && (
                <h1 className="heading-text">Webinars</h1>
              )}
              {allWebinars.length > 0 ? (
                allWebinars.map((web, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index + 1 !== allWebinars.length
                          ? "news-list"
                          : "news-list-last"
                      }
                    >
                      <Link
                        state={{ scroll: scrollPosition }}
                        className="no-link-hover"
                        to={`/${lan}/webinars/${web.node.slug.current}/`}
                      >
                        <img
                          src={web.node.image.asset.url}
                          alt="img"
                          loading="lazy"
                          className="news-image mb-20"
                          height={135}
                          width={135}
                        />
                      </Link>
                      <li className="news-li" data-id="2">
                        <div className="content-container">
                          <div className="content">
                            <div className="d-flex">
                              <div
                                style={{ width: "225px", minWidth: "217px" }}
                              >
                                <span>
                                  {lan === "en"
                                    ? "Published At"
                                    : "Veröffentlicht At"}
                                  {"   "}
                                  <span className="ml--5">
                                    {"   "}&bull;
                                    {moment(web.node.publishedAt)
                                      .local()
                                      .format("YYYY MMMM, DD")}
                                  </span>
                                </span>
                              </div>
                            </div>
                            <h4>
                              <Link
                                state={{
                                  scroll: scrollPosition,
                                }}
                                className="no-link-hover m-auto"
                                style={{ width: "85%" }}
                                to={`/${lan}/webinars/${web.node.slug.current}/`}
                              >
                                <h1 className="sen-dot hyphens">
                                  {hyphenated(web.node.title, {
                                    language: de,
                                  })}
                                </h1>
                              </Link>
                            </h4>
                            <p
                              className="text-align-left overflow-hidden ws-3"
                              style={{
                                height: "69px",
                              }}
                            >
                              {hyphenated(web.node.subDescription, {
                                language: de,
                              })}
                            </p>
                          </div>
                        </div>
                        <div
                          className="image"
                          data-url="/media/publications/haack_kueche.jpg"
                        ></div>
                      </li>
                    </div>
                  )
                })
              ) : (
                <div className="d-flex flex-direction-column">
                  <NoData />
                  <div className="content no-data-found">
                    <h2 className="no-data-text">
                      {lan === "en"
                        ? "Oops! No webinars found"
                        : "Hoppla! Keine Webinare gefunden"}
                    </h2>
                    <span>
                      {lan === "en" ? "Please" : "Bitte"}{" "}
                      <Link
                        to={`/${lan ? lan : "de"}/`}
                        style={{ color: "#E40036" }}
                      >
                        {lan === "en" ? "go back" : "zurück zur"}{" "}
                      </Link>
                      {lan === "en" ? "to home page." : "Startseite."}
                    </span>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query($locale: String, $fields: [SanityWebinarFieldsEnum] = _id) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    webinar: allSanityWebinar(sort: { fields: $fields, order: ASC }) {
      edges {
        node {
          publishedAt(locale: $locale)
          image {
            asset {
              url
            }
          }
          slug {
            current
          }
          subDescription {
            _type
            en
            de
          }
          title {
            _type
            en
            de
          }
        }
      }
    }
  }
`

export default Localize(Webinars)
